// Node Modules
import * as React from 'react';

// Components
import JoinUsFormWrapper from '../components/form/presentational/JoinUsFormWrapper';
import CourseListing from '../components/content-sections/courses/CourseListing';
import CoursesHero from '../components/content-sections/courses/CoursesHero';
import CoursesText from '../components/content-sections/courses/CoursesText';
import Layout from '../components/core/layout';
import SEO from '../components/core/seo';

const CoursesPage = () => (
  <Layout displayStickyButton={false}>
    <SEO title="Courses" />
    <CoursesHero />
    <CoursesText />
    <CourseListing />
    <JoinUsFormWrapper buttonText="Register" cta="Register for a Module" hideInterest interest="Register" />
  </Layout>
);

export default CoursesPage;
