// Node Modules
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Components
import YellowLink from '../../resuables/YellowLink';

// Assets
import { above, colors, space } from '../../../style/design-system';

const CourseListing = ({ className }) => (
  <div className={className}>
    <div className="container">
      <div className="row">
        <div className="col-10 offset-1 col-lg-8 offset-lg-2">
          <div className="one position-relative" to="/course/virtual-fit/">
            <StaticImage src="../../../images/courses/course-virtual-fit.jpg" alt="Virtual Fit Course" />
            <div className="place-right col-10 offset-1 col-sm-10 col-md-8 offset-md-4 col-lg-8 offset-lg-4 col-xl-8 offset-xl-4">
              <Link to="/course/virtual-fit/">
                <div className="white-box">
                  <h3 className="mt-0">Virtual Fit Module &mdash;&nbsp;15&nbsp;mins.</h3>
                  <p className="my-0">Helps leaders build successful remote teams by outlining and identifying the ideal characteristics of remote&nbsp;employees.</p>
                </div>
              </Link>
              <div className="d-flex jc-fe">
                <YellowLink to="/course/virtual-fit/">
                  Learn More
                </YellowLink>
              </div>
            </div>
          </div>
          <div className="two position-relative">
            <StaticImage className="image-flip" src="../../../images/courses/course-meaningful-connections.jpg" alt="Meaningful Connections Course" />
            <div className="place-left col-10 col-sm-10 col-md-8 col-lg-8 col-xl-8">
              <Link to="/course/meaningful-connections/">
                <div className="white-box">
                  <h3 className="mt-0">Meaningful Connections Module &mdash;&nbsp;15&nbsp;mins.</h3>
                  <p className="my-0">Offers a practical guide to forging a positive and productive remote work&nbsp;culture.</p>
                </div>
              </Link>
              <div className="d-flex jc-fs">
                <YellowLink to="/course/meaningful-connections/">
                  Learn More
                </YellowLink>
              </div>
            </div>
          </div>
          <div className="three position-relative">
            <StaticImage src="../../../images/courses/course-assuming-remote-control.jpg" alt="Assuming Remote Control Course" />
            <div className="place-right col-10 offset-1 col-sm-10 col-md-8 offset-md-4 col-lg-9 offset-lg-3 col-xl-8 offset-xl-4">
              <Link to="/course/assuming-remote-control/">
                <div className="white-box">
                  <h3 className="mt-0">Assuming Remote Control Module &mdash;&nbsp;12&nbsp;mins.</h3>
                  <p className="my-0">Provides managers with an applicable guide with tips on leading effective virtual&nbsp;meetings.</p>
                </div>
              </Link>
              <div className="d-flex jc-fe">
                <YellowLink to="/course/assuming-remote-control/">
                  Learn More
                </YellowLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default styled(CourseListing)`
  padding: ${space.g} 0;
  .place-left, .place-right {
    position: absolute;
  }
  .place-left {
    left: 0;
    bottom: -10rem;
    ${above.sm`
      left: -2rem;
      bottom: -5rem;
    `}
    ${above.md`
      left: -3rem;
      bottom: -5rem;
    `}
    ${above.lg`
      left: -5rem;
      bottom: 3rem;
    `}
    ${above.xl`
      left: -8rem;
      bottom: 7rem;
    `}
  }
  .place-right {
    right: 0;
    bottom: -10rem;
    ${above.sm`
      right: -2rem;
      bottom: -5rem;
    `}
    ${above.md`
      right: -3rem;
      bottom: -5rem;
    `}
    ${above.lg`
      right: -5rem;
      bottom: 3rem;
    `}
    ${above.xl`
      right: -8rem;
      bottom: 7rem;
    `}
  }
  a {
    color: ${colors.umaBlack};
    &:hover {
      text-decoration: none;
    }
  }
  .white-box {
    background-color: #fff;
    border: 1px solid ${colors.umaBlack};
    padding: ${space.f};
  }
  .one, .two, .three {
    margin-bottom: ${space.l};
    height: 15rem;
    ${above.sm`
      margin-bottom: ${space.j};
      height: 18rem;
    `}
    ${above.md`
      margin-bottom: ${space.k};
      height: 22rem;
    `}
    ${above.lg`
      height: 20rem;
      margin-bottom: ${space.h};
    `}
    ${above.xl`
      height: 28rem;
    `}
  }
  .gatsby-image-wrapper {
    height: 100%;
  }
  .two .gatsby-image-wrapper img {
    transform: rotateY(180deg);
  }
`;

CourseListing.propTypes = {
  className: PropTypes.string.isRequired,
};
