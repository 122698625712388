// Node Modules
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Style
import { space } from '../../../style/design-system';

const CoursesText = ({ className }) => (
  <div className={className}>
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
          <div className="above-blog-links text-center">
            <p className="text-center">We offer relevant training for today’s workforce managers who want to improve their soft skills when working in remote settings. These learning modules were created for our employer partners, who were looking to supplement their own professional development in the areas below. Please enjoy these complimentary learning modules to augment your team’s remote work&nbsp;development.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default styled(CoursesText)`
  padding-top: ${space.h};
`;

CoursesText.propTypes = {
  className: PropTypes.string.isRequired,
};
